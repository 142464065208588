import {
  DEFAULT_CITY_ID
} from 'utils/variable'
import {
  FETCH_HOME_CATEGORY_LIST,
  RECEIVE_HOME_CATEGORY_LIST,
  FAILED_HOME_CATEGORY_LIST,
  UPDATE_STATE_HOME_CATEGORY_LIST
} from '../../actions/types'

const initialState = {
  loading: false,
  filter: {
    page: 1,
    storeId: DEFAULT_CITY_ID
  },
  prime: [],
  list: [],
  listChild: [],
  dataSet: [],
  meta: {},
  errorMessage: null
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_HOME_CATEGORY_LIST:
      return { ...state, loading: true }

    case UPDATE_STATE_HOME_CATEGORY_LIST: {
      return { ...state, ...action.payload }
    }
    case RECEIVE_HOME_CATEGORY_LIST: {
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        meta: action.payload.meta
      }
    }
    case FAILED_HOME_CATEGORY_LIST:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.error
      }
    default:
      return state
  }
}
