import {
  FETCH_REVIEW,
  RECEIVE_REVIEW,
  FAILED_REVIEW
} from 'actions/types'

const initialState = {
  loading: false,
  meta: {},
  current: {},
  currentItem: {},
  errorMessage: null
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_REVIEW:
      return { ...state, loading: true }
    case RECEIVE_REVIEW:
      return {
        ...state,
        loading: false,
        current: action.payload.current,
        meta: action.payload.meta
      }
    case FAILED_REVIEW: {
      return {
        ...state,
        loading: false,
        current: {},
        meta: {},
        errorMessage: action.payload.error
      }
    }
    default:
      return state
  }
}
