import React from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'
import { DESCRIPTION, APPNAME } from '../../constants'

const CustomHelmet = ({
  title,
  meta = [],
  children,
  description
}) => {
  return (
    <Head>
      <title>{`${title} | ${DESCRIPTION}`}</title>
      <meta
        name="description"
        content={(description || DESCRIPTION).replace(/[^A-Za-z0-9 #|'+]+/g, '')}
      />
      <link async rel="shortcut icon" type="image/x-icon" href="/static/favicon.ico" />
      <link rel="preload" as="style" async type="text/css" href="/static/nprogress.css" />
      {children}
    </Head>
  )
}

CustomHelmet.propTypes = {
  meta: PropTypes.array,
  title: PropTypes.string,
  children: PropTypes.array
}

CustomHelmet.defaultProps = {
  meta: [],
  title: APPNAME
}

export default CustomHelmet
