import {
  FETCH_SHOPPING_CART,
  RECEIVE_SHOPPING_CART,
  RECEIVE_SHOPPING_CART_ID,
  ADD_SHOPPING_CART,
  PRODUCT_DETAIL_SHOPPING_CART,
  ADD_ITEM_SHOPPING_CART,
  CHANGE_CURRENT_PARAM_SHOPPING_CART,
  FAILED_SHOPPING_CART,
  UPDATE_STATE_SHOPPING_CART,
  UPDATE_QUANTITY_SHOPPING_CART,
  EDIT_QUANTITY_SHOPPING_CART,
  COUNT_SUB_TOTAL_SHOPPING_CART
} from '../../actions/types'

const initialState = {
  loading: true,
  shoppingCartId: null,
  list: [],
  meta: {},
  id: null,
  userId: null,
  productDetail: {},
  add: {},
  subTotal: 0,
  cartCount: 0,
  currentParam: {},
  errorMessage: null
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_SHOPPING_CART:
      return { ...state, loading: true, list: [] }
    case RECEIVE_SHOPPING_CART:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        meta: action.payload.meta
      }
    case UPDATE_STATE_SHOPPING_CART: {
      return {
        ...state,
        ...action.payload
      }
    }
    case UPDATE_QUANTITY_SHOPPING_CART: {
      state.list = state.list.map((item) => {
        if (item.productId === action.payload.item.productId) {
          return action.payload.item
        }
        return item
      })
      return {
        ...state,
        loading: false
      }
    }
    case EDIT_QUANTITY_SHOPPING_CART: {
      state.list = state.list.map((item) => {
        if (item.productId === action.payload.item.productId) {
          item.qty = action.payload.item.qty
          return item
        }
        return item
      }).filter((filtered) => filtered.qty > 0)
      return {
        ...state,
        loading: false
      }
    }
    case RECEIVE_SHOPPING_CART_ID: {
      if (action.payload.data) {
        return {
          ...state,
          loading: false,
          id: action.payload.data.id,
          userId: action.payload.data.userId
        }
      }
      return {
        ...state,
        loading: false
      }
    }
    case ADD_SHOPPING_CART:
      return {
        ...state,
        loading: false,
        add: action.payload.data
      }
    case PRODUCT_DETAIL_SHOPPING_CART:
      return {
        ...state,
        loading: false,
        productDetail: action.payload.data
      }
    case COUNT_SUB_TOTAL_SHOPPING_CART: {
      let cartCount = 0
      state.subTotal = state.list.map((item) => {
        cartCount += item.qty
        return item
      }).reduce((result, item) => {
        return result + (item.qty * item.price)
      }, 0)
      return {
        ...state,
        cartCount
      }
    }
    case ADD_ITEM_SHOPPING_CART:
      return {
        ...state,
        loading: false,
        add: action.payload.data
      }
    case CHANGE_CURRENT_PARAM_SHOPPING_CART:
      return {
        ...state,
        currentParam: action.payload.data
      }
    case FAILED_SHOPPING_CART:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.error
      }
    default:
      return state
  }
}
