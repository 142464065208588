import {
  DEFAULT_CITY_ID
} from 'utils/variable'
import {
  FETCH_HOME_PRODUCT_SIGNATURE,
  RECEIVE_HOME_PRODUCT_SIGNATURE,
  EDIT_QUANTITY_PRODUCT_SIGNATURE,
  UPDATE_STATE_PRODUCT_SIGNATURE,
  FAILED_HOME_PRODUCT_SIGNATURE
} from '../../actions/types'

const initialState = {
  lastPage: false,
  loading: false,
  filter: {
    page: 1,
    storeId: DEFAULT_CITY_ID
  },
  prime: [],
  list: [],
  dataSet: [],
  meta: {},
  errorMessage: null
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_HOME_PRODUCT_SIGNATURE:
      return { ...state, loading: true }
    case RECEIVE_HOME_PRODUCT_SIGNATURE: {
      let listNewItem = state.list.concat(action.payload.list)
      return {
        ...state,
        loading: false,
        list: listNewItem,
        meta: action.payload.meta
      }
    }
    case UPDATE_STATE_PRODUCT_SIGNATURE:
      return {
        ...state,
        ...action.payload
      }
    case EDIT_QUANTITY_PRODUCT_SIGNATURE: {
      state.list = state.list.map((item) => {
        if (item.id === action.payload.item.productId) {
          item.cartQty = action.payload.item.qty
          return item
        }
        return item
      })
      return {
        ...state,
        loading: false
      }
    }
    case FAILED_HOME_PRODUCT_SIGNATURE:
      if (action && action.payload && action.payload.data && action.payload.data.length === 0) {
        return {
          ...state,
          loading: false,
          lastPage: true,
          errorMessage: action.payload.error
        }
      }
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.error
      }
    default:
      return state
  }
}
