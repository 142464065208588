import {
  FETCH_USERFAV,
  RECEIVE_LIST_USERFAV,
  RECEIVE_CURRENT_ITEM_USERFAV,
  EDIT_QUANTITY_ITEM_USERFAV,
  FAILED_USERFAV,
  SUCCESS_SET_FAVOURITE,
  UPDATE_STATE_USERFAV
} from '../../actions/types'

const initialState = {
  lastPage: false,
  loading: false,
  list: [],
  meta: {},
  currentItemId: 0,
  currentItem: {},
  errorMessage: null
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_USERFAV:
      return { ...state, loading: true }
    case RECEIVE_LIST_USERFAV: {
      if (action.payload.list.length === 0) {
        return {
          ...state,
          loading: false,
          lastPage: true,
          meta: action.payload.meta
        }
      }
      const newList = state.list.concat(action.payload.list)
      return {
        ...state,
        loading: false,
        list: newList,
        lastPage: false,
        meta: action.payload.meta
      }
    }
    case UPDATE_STATE_USERFAV:
      return { ...state, ...action.payload }
    case SUCCESS_SET_FAVOURITE:
      return {
        loading: false,
        list: action.payload.list,
        meta: action.payload.meta
      }
    case RECEIVE_CURRENT_ITEM_USERFAV:
      return {
        ...state,
        loading: false,
        currentItem: action.payload.currentItem,
        currentItemId: action.payload.currentItemId
      }
    case EDIT_QUANTITY_ITEM_USERFAV:
      state.list = state.list.map((item) => {
        if (action.payload.data && item.id === action.payload.data.productId) {
          item.cartQty = action.payload.data.qty
          return item
        }
        return item
      })
      return {
        loading: false,
        list: state.list,
        meta: action.payload.meta
      }
    case FAILED_USERFAV:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.error
      }
    default:
      return state
  }
}
