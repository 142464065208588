import {
  FETCH_USER_PROFILE,
  RECEIVE_USER_PROFILE,
  UPDATE_STATE_USER_PROFILE,
  FAILED_USER_PROFILE
} from '../../actions/types'

const initialState = {
  isVisibleFormProductSuggestion: false,
  isVisibleModalLogout: false,

  loading: false,
  filter: {
    page: 1
  },
  list: [],
  dataSet: [],
  meta: {},
  errorMessage: null
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_USER_PROFILE:
      return { ...state, loading: true }
    case RECEIVE_USER_PROFILE:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        meta: action.payload.meta
      }
    case FAILED_USER_PROFILE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage
      }
    case UPDATE_STATE_USER_PROFILE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
