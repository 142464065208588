import {
  FETCH_USER,
  RECEIVE_USER,
  RECEIVE_ITEM_USER,
  RESET_ITEM_USER,
  SUCCESS_USER,
  UPDATE_STATE_USER,
  FAILED_USER
} from '../actions/types'

const initialState = {
  loading: false,
  filter: {
    page: 1
  },
  user: {},
  list: [],
  dataSet: [],
  meta: {},
  errorMessage: null
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SUCCESS_USER:
      return { ...state, meta: action.payload.meta }
    case FETCH_USER:
      return { ...state, loading: true }
    case RESET_ITEM_USER:
      return {
        ...state,
        loading: false,
        currentItem: {},
        errorMessage: null
      }
    case RECEIVE_USER:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        meta: action.payload.meta
      }
    case RECEIVE_ITEM_USER:
      return {
        ...state,
        loading: false,
        currentItem: action.payload.currentItem
      }
    case FAILED_USER:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage
      }
    case UPDATE_STATE_USER:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
