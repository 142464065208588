import {
  FETCH_WALLET,
  RECEIVE_WALLET,
  FAILED_WALLET,
  UPDATE_STATE_WALLET
} from '../../actions/types'

const initialState = {
  loading: false,
  filter: {
    page: 1
  },
  data: [],
  dataSet: [],
  meta: {}
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_WALLET:
      return { ...state, loading: true }
    case RECEIVE_WALLET:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        meta: action.payload.meta
      }
    case FAILED_WALLET:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage
      }
    case UPDATE_STATE_WALLET:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
