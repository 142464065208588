import {
  SUCCESS_ORDER,
  FETCH_ORDER,
  RECEIVE_ORDER,
  RECEIVE_ORDER_LOG,
  RECEIVE_ITEM_ORDER,
  RECEIVE_ITEM_ORDER_DETAIL,
  FAILED_ORDER,
  UPDATE_STATE_ORDER
} from 'actions/types'

const initialState = {
  loading: false,
  isPrivate: false,
  filter: {
    q: '',
    page: 1
  },
  orderList: [],
  list: [],
  listLog: [],
  dataSet: [],
  meta: {},
  current: {},
  currentItem: {},
  errorMessage: null
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SUCCESS_ORDER:
      return { ...state, meta: action.payload.meta }
    case FETCH_ORDER:
      return { ...state, loading: true }
    case RECEIVE_ORDER:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        meta: action.payload.meta
      }
    case RECEIVE_ITEM_ORDER:
      return {
        ...state,
        loading: false,
        current: action.payload.data
      }
    case RECEIVE_ITEM_ORDER_DETAIL:
      return {
        ...state,
        loading: false,
        currentItem: action.payload.data,
        meta: action.payload.meta
      }
    case RECEIVE_ORDER_LOG:
      return {
        ...state,
        loading: false,
        listLog: action.payload.list,
        meta: action.payload.meta
      }
    case FAILED_ORDER: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.error
      }
    }
    case UPDATE_STATE_ORDER:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
