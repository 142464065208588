import {
  FETCH_STORE_MAP,
  RECEIVE_STORE_MAP,
  ADD_STORE_MAP,
  UPDATE_STORE_MAP,
  SET_DINE_IN_STATUS,
  FAILED_STORE_MAP
} from '../../actions/types'

const initialState = {
  loading: true,
  meta: {},
  storeMapData: null,
  dineInStatus: false,
  errorMessage: null
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_STORE_MAP:
      return { ...state, loading: true, list: [] }
    case RECEIVE_STORE_MAP:
      return {
        ...state,
        loading: false,
        storeMapData: action.payload.data,
        meta: action.payload.meta
      }
    case ADD_STORE_MAP:
      return {
        ...state,
        loading: false,
        storeMapData: action.payload.data,
        meta: action.payload.meta
      }
    case UPDATE_STORE_MAP: {
      if (state.storeMapData) { state.storeMapData.storeId = action.payload.data }
      return {
        ...state,
        loading: false
      }
    }
    case SET_DINE_IN_STATUS: {
      return {
        ...state,
        dineInStatus: action.payload.data,
        loading: false
      }
    }
    case FAILED_STORE_MAP:
      return {
        ...state,
        loading: false,
        id: action && action.payload && action.payload.data && action.payload.data.id,
        userId: action && action.payload && action.payload.data && action.payload.data.userId
      }
    default:
      return state
  }
}
