import {
  DEFAULT_CITY_ID
} from 'utils/variable'
import {
  FETCH_HOME_WEEKLY_TREND,
  RECEIVE_HOME_WEEKLY_TREND,
  EDIT_QUANTITY_WEEKLY_TREND,
  FAILED_HOME_WEEKLY_TREND
} from '../../actions/types'

const initialState = {
  loading: false,
  filter: {
    page: 1,
    storeId: DEFAULT_CITY_ID
  },
  prime: [],
  list: [],
  dataSet: [],
  meta: {},
  errorMessage: null
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_HOME_WEEKLY_TREND:
      return { ...state, loading: true }
    case RECEIVE_HOME_WEEKLY_TREND:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        meta: action.payload.meta
      }
    case EDIT_QUANTITY_WEEKLY_TREND: {
      state.list = state.list.map((item) => {
        if (item.id === action.payload.item.productId) {
          item.cartQty = action.payload.item.qty
          return item
        }
        return item
      })
      return {
        ...state,
        loading: false
      }
    }
    case FAILED_HOME_WEEKLY_TREND:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.error
      }
    default:
      return state
  }
}
