import {
  FETCH_SELECT_ADDRESS,
  RECEIVE_SELECT_ADDRESS,
  RECEIVE_ITEM_SELECT_ADDRESS,
  RECEIVE_DESCRIPTION_RAW_SELECT_ADDRESS,
  UPDATE_LATLNG_ITEM_SELECT_ADDRESS,
  CLEAR_ITEM_SELECT_ADDRESS,
  SUCCESS_SELECT_ADDRESS,
  SET_CURRENT_LNGLAT_SELECT_ADDRESS,
  FAILED_SELECT_ADDRESS,
  SET_MAIN_ADDRESS,
  GET_DINE_IN_ADDRESS,
  ADD_DINE_IN_ADDRESS,
  UPDATE_DINE_IN_ADDRESS,
  UPDATE_STATE_SELECT_ADDRESS,
  UPDATE_STATE_ADDRESS_SUGGESTION,
  RECEIVE_ADDRESS_SUGGESTION_LIST,
  RECEIVE_ADDRESS_DETAIL
} from '../../actions/types'

const initialState = {
  locationMessage: null,

  loading: false,
  prime: [],
  list: [],
  addressSuggestion: [],
  addressDetail: {},
  dataSet: [],
  meta: {},
  mainAddress: {},
  currentItem: {},
  currentlng: 0.0,
  currentlat: 0.0,
  errorMessage: null,
  dineInAddress: {}
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_SELECT_ADDRESS:
      return { ...state, loading: true }
    case RECEIVE_SELECT_ADDRESS:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        meta: action.payload.meta
      }
    case UPDATE_STATE_SELECT_ADDRESS:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_STATE_ADDRESS_SUGGESTION:
      return {
        addressSuggestion: action.payload.addressSuggestion
      }
    case RECEIVE_ITEM_SELECT_ADDRESS:
      return {
        ...state,
        loading: false,
        currentItem: action.payload.data,
        meta: action.payload.meta
      }
    case UPDATE_LATLNG_ITEM_SELECT_ADDRESS:
      // eslint-disable-next-line no-case-declarations
      let { currentItem } = state
      if (action.payload.data) {
        currentItem = {
          latitude: action.payload.data.latitude,
          longitude: action.payload.data.longitude
        }
      }
      return {
        ...state,
        loading: false,
        currentItem,
        meta: action.payload.meta
      }
    case RECEIVE_DESCRIPTION_RAW_SELECT_ADDRESS: {
      const item = state.currentItem
      if (action.payload.data) {
        item.descriptionRaw = action.payload.data.formatted_address
      }
      return {
        ...state,
        loading: false,
        currentItem: item,
        meta: action.payload.meta
      }
    }
    case RECEIVE_ADDRESS_SUGGESTION_LIST: {
      return {
        ...state,
        loading: false,
        addressSuggestion: action.payload.data,
        meta: action.payload.meta
      }
    }
    case RECEIVE_ADDRESS_DETAIL: {
      return {
        ...state,
        loading: false,
        addressDetail: action.payload.data,
        meta: action.payload.meta
      }
    }
    case CLEAR_ITEM_SELECT_ADDRESS:
      return {
        ...state,
        loading: false,
        currentItem: {}
      }
    case SUCCESS_SELECT_ADDRESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        meta: action.payload.meta,
        errorMessage: null
      }
    case SET_MAIN_ADDRESS:
      return {
        ...state,
        mainAddress: action.payload.mainAddress,
        errorMessage: null
      }
    case SET_CURRENT_LNGLAT_SELECT_ADDRESS: {
      let currentlng = 0
      let currentlat = 0
      if (action.payload.data) {
        currentlng = action.payload.data.currentlng
        currentlat = action.payload.data.currentlat
      }
      return {
        ...state,
        currentlng,
        currentlat,
        errorMessage: null
      }
    }
    case GET_DINE_IN_ADDRESS: {
      return {
        ...state,
        dineInAddress: action.payload.data,
        errorMessage: null
      }
    }
    case ADD_DINE_IN_ADDRESS:
      return {
        ...state,
        errorMessage: null
      }
    case UPDATE_DINE_IN_ADDRESS:
      return {
        ...state,
        errorMessage: null
      }
    case FAILED_SELECT_ADDRESS:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.error
      }
    default:
      return state
  }
}
