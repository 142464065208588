import {
  FETCH_SEARCH,
  RECEIVE_SEARCH,
  FAILED_SEARCH,
  RECEIVE_ITEM_SEARCH,
  UPDATE_STATE_SEARCH
} from 'actions/types'

const initialState = {
  loading: false,
  filter: {
    page: 1
  },
  list: [],
  dataSet: [],
  currentItem: {},
  meta: {},
  errorMessage: null
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_SEARCH:
      return {
        ...state,
        loading: true,
        errorMessage: null
      }
    case RECEIVE_SEARCH:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        meta: action.payload.meta,
        errorMessage: null
      }
    case RECEIVE_ITEM_SEARCH:
      return {
        ...state,
        loading: false,
        currentItem: action.payload.currentItem,
        meta: action.payload.meta,
        errorMessage: null
      }
    case FAILED_SEARCH:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage
      }
    case UPDATE_STATE_SEARCH: {
      return { ...state, ...action.payload }
    }
    default:
      return state
  }
}
