import { set } from 'utils/storage'

export const DEFAULT_QUESTION = 'What is the reason you want to join us?'
export const DEFAULT_API_MEET = 'k3mart.id'

export const DEFAULT_CITY_ID = 153
export const DEFAULT_CITY_NAME = 'Jakarta Selatan'

export const TRANSFER = 1
export const VIRTUAL_ACCOUNT = 2

export const REGIONAL = {
  storeId: DEFAULT_CITY_ID,
  cityName: DEFAULT_CITY_NAME
}

export const STRINGIFY_REGIONAL = (value) => {
  value = REGIONAL
  let data = JSON.stringify(value)
  return data
}

export const PARSE_REGIONAL = (value) => {
  let data = JSON.parse(value)
  return data
}

export const SET_DEFAULT_REGIONAL = async (value) => {
  value = REGIONAL
  let regional = await STRINGIFY_REGIONAL(value)
  await set('@regional', regional)
}
