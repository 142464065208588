import {
  FETCH_ANNOUNCEMENT,
  RECEIVE_ANNOUNCEMENT,
  FAILED_ANNOUNCEMENT
} from '../../actions/types'

const initialState = {
  loading: false,
  announcement: ''
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_ANNOUNCEMENT:
      return { ...state, loading: true }
    case RECEIVE_ANNOUNCEMENT:
      return {
        ...state,
        announcement: action.payload.data
      }
    case FAILED_ANNOUNCEMENT:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}
