import {
  FAILED_VOUCHER,
  FETCH_VOUCHER,
  CHANGE_VALUE_TAB,
  RECEIVE_LIST_VOUCHER,
  REDEEM_VOUCHER,
  RECEIVE_DETAIL_VOUCHER,
  RECEIVE_MY_VOUCHER
} from '../../actions/types'

const initialState = {
  loading: false,
  successRedeem: null,
  valueTab: 0,
  totalListVoucher: null,
  totalMyVoucher: null,
  listVoucher: [],
  myVoucher: [],
  detailVoucher: {},
  redeemVoucher: {},
  errorMessage: null
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_VOUCHER:
      return {
        ...state,
        loading: true,
        successRedeem: null,
        errorMessage: null
      }
    case CHANGE_VALUE_TAB:
      return {
        ...state,
        loading: false,
        valueTab: action.payload.data,
        errorMessage: null
      }
    case RECEIVE_LIST_VOUCHER:
      return {
        ...state,
        loading: false,
        totalListVoucher: action.payload.total,
        listVoucher: action.payload.data,
        errorMessage: null
      }
    case RECEIVE_MY_VOUCHER:
      return {
        ...state,
        loading: false,
        totalMyVoucher: action.payload.total,
        myVoucher: action.payload.data,
        errorMessage: null
      }
    case RECEIVE_DETAIL_VOUCHER:
      return {
        ...state,
        loading: false,
        detailVoucher: action.payload.data,
        errorMessage: null
      }
    case REDEEM_VOUCHER:
      return {
        ...state,
        loading: false,
        successRedeem: action.payload.message,
        errorMessage: null
      }
    case FAILED_VOUCHER:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.error
      }
    default:
      return state
  }
}
