import {
  FETCH_CATEGORY,
  RECEIVE_CATEGORY,
  UPDATE_STATE_CATEGORY,
  FAILED_CATEGORY
} from '../../actions/types'

const initialState = {
  openedCategory: null,

  loading: false,
  filter: {
    page: 1
  },
  list: [],
  listChild: [],
  dataSet: [],
  meta: {},
  errorMessage: null
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_CATEGORY:
      return { ...state, loading: true }
    case RECEIVE_CATEGORY:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        meta: action.payload.meta
      }
    case FAILED_CATEGORY:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage
      }
    case UPDATE_STATE_CATEGORY:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
