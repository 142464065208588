import {
  FETCH_STORE,
  RECEIVE_STORE,
  RECEIVE_CLOSEST_STORE,
  RECEIVE_OPERATIONAL_HOUR_STORE,
  UPDATE_STATE_STORE,
  SET_CURRENT_STORE,
  FAILED_STORE
} from '../../actions/types'

const initialState = {
  loading: true,
  list: [],
  meta: {},
  closestStore: [],
  operationalHour: {},
  currentStore: {},
  errorMessage: null
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_STORE:
      return { ...state, loading: true, list: [] }
    case RECEIVE_STORE:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        meta: action.payload.meta
      }
    case RECEIVE_CLOSEST_STORE:
      return {
        ...state,
        loading: false,
        closestStore: action.payload.list,
        meta: action.payload.meta
      }
    case RECEIVE_OPERATIONAL_HOUR_STORE:
      return {
        ...state,
        loading: false,
        operationalHour: action.payload.data,
        meta: action.payload.meta
      }
    case UPDATE_STATE_STORE: {
      if (action.payload.data) {
        return {
          ...state,
          loading: false,
          id: action.payload.data.id,
          userId: action.payload.data.userId
        }
      }
      return {
        ...state,
        loading: false
      }
    }
    case SET_CURRENT_STORE: {
      return {
        ...state,
        loading: false,
        currentStore: action.payload.data
      }
    }
    case FAILED_STORE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.error
      }
    default:
      return state
  }
}
