import { createMuiTheme } from '@material-ui/core/styles'
import color from './color'

// Create a theme instance.
export const theme = {
  root: {
    height: '100%'
  },
  palette: {
    primary: {
      main: color.primaryColor,
      light: color.lightPrimaryColor,
      // dark: color.backgroundColor,
      contrastText: color.white
    },
    secondary: {
      main: color.grayButton,
      light: color.lightPrimaryColor,
      dark: color.backgroundColor,
      contrastText: color.primaryColor
    },
    error: {
      main: color.errorColor,
      light: color.errorColor,
      dark: color.errorColor,
      contrastText: color.textIcons
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'BouyguesRead',
      'sans-serif',
      'mosk',
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          {
            fontFamily: [
              'BouyguesRead',
              'sans-serif',
              'mosk',
              'Roboto',
              '-apple-system',
              'BlinkMacSystemFont',
              '"Segoe UI"',
              '"Helvetica Neue"',
              'Arial',
              '"Apple Color Emoji"',
              '"Segoe UI Emoji"',
              '"Segoe UI Symbol"'
            ].join(','),
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 300
          }
        ]
      }
    }
  }
}

const muiTheme = createMuiTheme(theme)

export default muiTheme
