import {
  FETCH_PRODUCT,
  RECEIVE_PRODUCT,
  RECEIVE_SEARCH_PRODUCT,
  EDIT_QTY_SEARCH_PRODUCT,
  RECEIVE_ITEM_PRODUCT,
  CLEAN_CURRENT_ITEM_PRODUCT,
  EDIT_QUANTITY_PRODUCT,
  FAILED_PRODUCT,
  UPDATE_STATE_PRODUCT
} from '../../actions/types'

const initialState = {
  lastPage: false,
  loading: false,
  filter: {
    q: '',
    page: 1,
    pageSize: 15
  },
  list: [],
  listSearch: [],
  meta: {},
  product: {},
  currentItem: {},
  errorMessage: null
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_PRODUCT:
      return { ...state, loading: true }
    case RECEIVE_PRODUCT:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        meta: action.payload.meta
      }
    case RECEIVE_SEARCH_PRODUCT: {
      if (action.payload.list.length === 0) {
        return {
          ...state,
          loading: false,
          lastPage: true,
          meta: action.payload.meta
        }
      }
      const listNewSearch = state.listSearch.concat(action.payload.list)
      return {
        ...state,
        loading: false,
        listSearch: listNewSearch,
        meta: action.payload.meta
      }
    }
    case EDIT_QTY_SEARCH_PRODUCT: {
      state.listSearch = state.listSearch.map((item) => {
        if (item.id === action.payload.item.productId) {
          item.cartQty = action.payload.item.qty
          return item
        }
        return item
      })
      return {
        ...state,
        loading: false
      }
    }
    case RECEIVE_ITEM_PRODUCT:
      return {
        ...state,
        loading: false,
        currentItem: action.payload.currentItem
      }
    case CLEAN_CURRENT_ITEM_PRODUCT:
      return {
        ...state,
        currentItem: {},
        loading: false
      }
    case EDIT_QUANTITY_PRODUCT:
      if (action.payload.data && action.payload.product) {
        action.payload.product.cartQty = action.payload.data.qty
      }
      return {
        ...state,
        currentItem: action.payload.product,
        loading: false
      }
    case FAILED_PRODUCT:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.error
      }
    case UPDATE_STATE_PRODUCT:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
